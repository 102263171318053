import { useContext, useEffect, useMemo, useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import { OfferBM, ServicelogV2FM } from '@bm-js/h2o-shared';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import TabCard from '../components/TabCard';
import ServicelogInvoicingTab from '../components/ServicelogInvoicingTab';
import { do_url } from '../cnst/server.cnst';
import { parseCustomerName } from '../utils/parseCustomerName.util';
import { ActionType } from '../types/dispatch.types';
import OfferInvoicingTab from '../components/OfferInvoicingTab';
import { _createServicelogFromOffer } from '../utils/offer.util';
import { useNavigate } from 'react-router';

const InvoicingPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  const [servicelogs, setServicelogs] = useState<ServicelogV2FM[]>([]);
  const [offers, setOffers] = useState<OfferBM[]>([]);
  const [activeServicelogTab, setActiveServicelogTab] = useState(0);
  const [activeOfferTab, setActiveOfferTab] = useState(0);

  const getServicelogs = async () => {
    const { data, err } = await request<ServicelogV2FM[]>({
      state,
      dispatch,
      path: 'servicelogs',
      skipLoader: true,
    });
    if (err || !data) return;
    setServicelogs(data);
  };

  const getOffers = async () => {
    const { data, err } = await request<OfferBM[]>({
      state,
      dispatch,
      path: 'offers',
      skipLoader: true,
    });
    if (err || !data) return;
    setOffers(data);
  };

  const getData = async () => {
    dispatch({ type: ActionType.LOADING, value: true });
    await Promise.all([getServicelogs(), getOffers()]);
    dispatch({ type: ActionType.LOADING, value: false });
  };

  const getServicelogPdf = (servicelog: ServicelogV2FM, download?: boolean) => {
    const customerName = parseCustomerName(
      servicelog.customer.customerInformation.name
    );

    const qps = new URLSearchParams({
      download: String(download),
    }).toString();

    const url = `${do_url}servicelogs/${state.token}/get-document/${customerName}/${servicelog._id}/${servicelog.offerNumber}?${qps}`;
    window.open(url);
  };

  const getOfferPdf = (offer: OfferBM, download?: boolean) => {
    const customerName = parseCustomerName(
      offer.customer.customerInformation.name
    );

    const qps = new URLSearchParams({
      download: String(download),
    }).toString();

    const url = `${do_url}offers/${state.token}/get-document/${customerName}/${offer._id}/${offer.offerNumber}?${qps}`;
    window.open(url);
  };

  const updateServicelogStatus = async (
    servicelog: ServicelogV2FM,
    status: string
  ) => {
    const { err } = await request({
      path: `servicelogs/update-status/${servicelog._id}`,
      method: 'PUT',
      state,
      dispatch,
      body: { status },
      successText: 'Status uppdaterad',
    });

    if (err) return;
    await getServicelogs();
  };

  const updateOfferStatus = async (offer: OfferBM, status: string) => {
    const { err } = await request({
      path: `offers/update-order-status/${offer._id}`,
      method: 'PUT',
      state,
      dispatch,
      body: { status },
      successText: 'Status uppdaterad',
    });

    if (err) return;

    if (status === 'Beställd') {
      await _createServicelogFromOffer({
        offer,
        state,
        dispatch,
        customerId: offer.customer._id,
        customerName: offer.customer.customerInformation.name,
        navigate,
      });
      return;
    }

    await getOffers();
  };

  const goToEdit = (servicelog: ServicelogV2FM) => {
    navigate(
      `/servicelogs/?id=${servicelog.customer._id}&name=${parseCustomerName(
        servicelog.customer
      )}&idToOpen=${servicelog._id}`
    );
  };

  const groupedServiceLogs = useMemo(() => {
    const open = servicelogs.filter((s) => s.status === 'Öppen');
    const readyForReview = servicelogs.filter(
      (s) => s.status === 'Klar för granskning'
    );
    const readyForInvoicing = servicelogs.filter(
      (s) => s.status === 'Klar för fakturering'
    );
    const invoiced = servicelogs.filter((s) => s.status === 'Fakturerad');
    return [open, readyForReview, readyForInvoicing, invoiced];
  }, [servicelogs]);

  const groupedOffers = useMemo(() => {
    const open = offers.filter((s) => s.orderStatus === 'Öppen');
    const rejected = offers.filter((s) => s.orderStatus === 'Avböjd');
    const ordered = offers.filter((s) => s.orderStatus === 'Beställd');
    return [open, rejected, ordered];
  }, [offers]);

  useEffect(() => {
    if (state.token) getData();
  }, [state.token]);

  return (
    <>
      <h1 className="page-header">Fakturering</h1>
      <Breadcrumbs crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Fakturering' }]} />
      <h2>Serviceloggar</h2>
      <TabCard
        activeTab={activeServicelogTab}
        setActiveTab={setActiveServicelogTab}
        tabs={[
          'Öppna',
          'Klara för granskning',
          'Klara för fakturering',
          'Fakturerade',
        ]}
        contents={groupedServiceLogs.map((group) => (
          <ServicelogInvoicingTab
            servicelogs={group}
            updateStatus={updateServicelogStatus}
            goToEdit={goToEdit}
            getPdf={getServicelogPdf}
          />
        ))}
      />
      <h2>Offerter</h2>
      <TabCard
        activeTab={activeOfferTab}
        setActiveTab={setActiveOfferTab}
        tabs={['Öppna', 'Avböjda', 'Beställda']}
        contents={groupedOffers.map((group) => (
          <OfferInvoicingTab
            offers={group}
            updateStatus={updateOfferStatus}
            getPdf={getOfferPdf}
          />
        ))}
      />
    </>
  );
};

export default InvoicingPage;
