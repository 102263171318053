import { FormEventHandler, useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import NotificationCard from '../components/NotificationCard';
import TabCard from '../components/TabCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import { pDelay } from '../utils/misc.util';
import DeleteNotificationModal from '../components/DeleteNotificationModal';
import CreateInternalNotificationModal from '../components/CreateInternalNotificationModal';
import EditInternalNotificationModal from '../components/EditInternalNotificationModal';
import InternalNotificationModal from '../components/InternalNotificationModal';
import NotificationActions from '../components/NotificationActions';
import {
  Notification,
  UserFM,
  UserMinimalNoNotifications,
} from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import { captureException } from '@sentry/react';

const NotificationsRoute = () => {
  const state = useContext(GlobalStateContext);
  const stateUser = state.user as UserFM;
  const dispatch = useContext(GlobalDispatchContext);

  const [notifications, setNotifications] = useState<{
    my: { active: Notification[]; archived: Notification[] };
    other: { active: Notification[]; archived: Notification[] };
  }>({
    my: { active: [], archived: [] },
    other: { active: [], archived: [] },
  });

  const [myNotificationsActiveTab, setMyNotificationsActiveTab] = useState(0);
  const [otherNotificationsActiveTab, setOtherNotificationsActiveTab] =
    useState(0);

  const [allUsers, setAllUsers] = useState<UserMinimalNoNotifications[]>([]);

  const initialInternalNotification: Partial<Notification> = {
    isInternal: true,
    subject: '',
    recipientId: '',
    recipientFirstName: '',
    recipientLastName: '',
    dateForHandling: '',
    textContent: '',
    read: false,
  };

  const [newInternalNotification, setNewInternalNotification] = useState<{
    active: boolean;
    notification: Partial<Notification>;
  }>({
    active: false,
    notification: initialInternalNotification,
  });
  const [editInternalNotification, setEditInternalNotification] = useState<{
    active: boolean;
    notification?: Notification;
  }>({
    active: false,
  });

  const [batchSelection, setBatchSelection] = useState<Notification[]>([]);

  const [deleteNotificationPending, setDeleteNotificationPending] = useState<{
    active: boolean;
    notification?: Notification;
  }>({
    active: false,
  });

  const [viewInternalNotificationModal, setViewInternalNotificationModal] =
    useState<{ active: boolean; notification?: Notification }>({
      active: false,
    });

  const getAllUsers = async () => {
    const { data } = await request<UserMinimalNoNotifications[]>({
      state,
      dispatch,
      path: 'users/auth/all-users/no-notifications',
      skipLoader: true,
    });

    if (!data) return;
    const filteredUsers = data.filter((u) => {
      if (u.firstName === 'Patrik' && u.lastName === 'Hellberg') return false;
      else if (u.firstName === 'Bucket' && u.lastName === 'Media') return false;
      else return true;
    });
    setAllUsers(filteredUsers);
  };

  const getNotifications = async () => {
    const { data } = await request<{
      my: { active: Notification[]; archived: Notification[] };
      other: { active: Notification[]; archived: Notification[] };
    }>({
      state,
      dispatch,
      path: `notifications/get/${stateUser._id}`,
    });
    if (!data) return;
    setNotifications(data);
  };

  const createInternalNotification: FormEventHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: ActionType.LOADING, value: true });
    const body = { ...newInternalNotification.notification };
    if (body.recipientId !== 'allUsers') {
      body.recipientLastName = allUsers.filter(
        (u) => u._id === body.recipientId
      )[0].lastName;
      body.recipientFirstName = allUsers.filter(
        (u) => u._id === body.recipientId
      )[0].firstName;
    }

    const { err } = await request({
      state,
      dispatch,
      body,
      successText: 'Notis sparad',
      method: 'POST',
      path: `notifications/add-internal-notification/${body.recipientId}`,
    });

    if (err) return;

    await pDelay(1000);
    setNewInternalNotification({
      active: false,
      notification: initialInternalNotification,
    });
    getNotifications();
  };

  const archiveNotification = async (notification: Notification) => {
    dispatch({ type: ActionType.LOADING, value: true });

    const { err, data } = await request<{
      numberOfUnreadNotifications: number;
    }>({
      method: 'POST',
      state,
      dispatch,
      path: `notifications/archive/${notification.recipientId}/${notification._id}`,
      successText: 'Notis arkiverad',
    });

    if (err || !data) return;
    getNotifications();
    dispatch({
      type: ActionType.SET_NUMBER_OF_UNREAD_NOTIFICATIONS,
      value: data.numberOfUnreadNotifications,
    });
  };

  const deleteNotification = async (notification?: Notification) => {
    if (!notification) return;
    const { err } = await request({
      method: 'DELETE',
      state,
      dispatch,
      path: `notifications/delete/${notification.recipientId}/${notification._id}`,
      successText: 'Notis raderad',
    });
    if (err) return;
    setDeleteNotificationPending({
      ...deleteNotificationPending,
      active: false,
    });
    getNotifications();
  };

  const preDeleteNotification = (notification: Notification) => {
    setDeleteNotificationPending({
      active: true,
      notification: notification,
    });
  };

  const showInternalNotification = (notification: Notification) => {
    setViewInternalNotificationModal({
      active: true,
      notification,
    });
  };

  const editNotificationInit = (notification: Notification) => {
    setEditInternalNotification({
      active: true,
      notification,
    });
  };

  const updateInternalNotification: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!editInternalNotification.notification) return;
    const { _id } = editInternalNotification.notification;

    const allNotifications: Notification[] = [];
    allNotifications.push(...notifications.my.active);
    allNotifications.push(...notifications.my.archived);
    allNotifications.push(...notifications.other.active);
    allNotifications.push(...notifications.other.archived);

    const originalNotification = allNotifications.find((n) => n._id === _id);
    const user = allUsers.find(
      (u) => u._id === editInternalNotification.notification?.recipientId
    );

    const body = {
      ...editInternalNotification.notification,
      recipientFirstName: user?.firstName,
      recipientLastName: user?.lastName,
    };

    if (!originalNotification) {
      captureException('Notification not found');
      return;
    }

    const { err } = await request({
      method: 'POST',
      state,
      dispatch,
      path: `notifications/edit-internal-notification/${originalNotification.recipientId}/${_id}`,
      successText: 'Notis uppdaterad',
      body,
    });

    if (err) return;
    setEditInternalNotification({
      ...editInternalNotification,
      active: false,
    });
    getNotifications();
  };

  const toggleBatchSelection = (notification: Notification) => {
    const updated = [...batchSelection];
    const index = batchSelection.findIndex((n) => n._id === notification._id);
    if (index === -1) {
      updated.push(notification);
    } else {
      updated.splice(index, 1);
    }
    setBatchSelection(updated);
  };

  const toggleReadStatus = async (notification: Notification) => {
    const { err, data } = await request<{
      numberOfUnreadNotifications: number;
    }>({
      method: 'POST',
      state,
      dispatch,
      path: 'notifications/toggle-read-status',
      successText: 'Notis uppdaterad',
      body: notification,
    });
    if (err || !data) return;
    getNotifications();
    dispatch({
      type: ActionType.SET_NUMBER_OF_UNREAD_NOTIFICATIONS,
      value: data.numberOfUnreadNotifications,
    });
  };

  useEffect(() => {
    if (state.token) {
      getNotifications();
      getAllUsers();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <div>
      <h1 className="page-header">Notiser</h1>
      <Breadcrumbs
        crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Notiser' }]}
        rightContent={
          <Button
            style={{ whiteSpace: 'nowrap' }}
            small
            onClick={() =>
              setNewInternalNotification({
                ...newInternalNotification,
                active: true,
              })
            }
          >
            Skapa intern notis
          </Button>
        }
      />
      <NotificationActions
        batchSelection={batchSelection}
        setBatchSelection={setBatchSelection}
        revalidate={getNotifications}
      />
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <TabCard
            activeTab={myNotificationsActiveTab}
            setActiveTab={setMyNotificationsActiveTab}
            tabs={['Mina notiser', 'Mina arkiverade notiser']}
            contents={[
              notifications.my.active.map((notification) => (
                <NotificationCard
                  showUnreadAlert
                  toggleReadStatus={toggleReadStatus}
                  batchSelection={batchSelection}
                  toggleBatchSelection={toggleBatchSelection}
                  notification={notification}
                  key={notification._id}
                  my
                  editNotification={editNotificationInit}
                  hasEdit={
                    notification.isInternal && stateUser.role === 'Admin'
                  }
                  showInternalNotification={showInternalNotification}
                  preDeleteNotification={preDeleteNotification}
                  archiveNotification={archiveNotification}
                />
              )),
              notifications.my.archived.map((notification) => (
                <NotificationCard
                  batchSelection={batchSelection}
                  toggleBatchSelection={toggleBatchSelection}
                  notification={notification}
                  key={notification._id}
                  my
                  editNotification={editNotificationInit}
                  hasEdit={
                    notification.isInternal && stateUser.role === 'Admin'
                  }
                  showInternalNotification={showInternalNotification}
                  preDeleteNotification={preDeleteNotification}
                  archived
                />
              )),
            ]}
          />
        </div>
        <div className="single-customer-right-content">
          <TabCard
            activeTab={otherNotificationsActiveTab}
            setActiveTab={setOtherNotificationsActiveTab}
            tabs={['Andras notiser', 'Andras arkiverade notiser']}
            contents={[
              notifications.other.active.map((notification) => (
                <NotificationCard
                  batchSelection={batchSelection}
                  toggleBatchSelection={toggleBatchSelection}
                  my={stateUser.role === 'Admin'}
                  notification={notification}
                  key={notification._id}
                  editNotification={editNotificationInit}
                  hasEdit={
                    notification.isInternal && stateUser.role === 'Admin'
                  }
                  showInternalNotification={showInternalNotification}
                  preDeleteNotification={preDeleteNotification}
                  archiveNotification={archiveNotification}
                />
              )),
              notifications.other.archived.map((notification) => (
                <NotificationCard
                  batchSelection={batchSelection}
                  toggleBatchSelection={toggleBatchSelection}
                  notification={notification}
                  key={notification._id}
                  editNotification={editNotificationInit}
                  hasEdit={
                    notification.isInternal && stateUser.role === 'Admin'
                  }
                  showInternalNotification={showInternalNotification}
                  my={stateUser.role === 'Admin'}
                  preDeleteNotification={preDeleteNotification}
                  archived
                />
              )),
            ]}
          />
        </div>
      </div>
      <DeleteNotificationModal
        deleteNotification={deleteNotification}
        deleteNotificationPending={deleteNotificationPending}
        setDeleteNotificationPending={setDeleteNotificationPending}
      />
      <CreateInternalNotificationModal
        newInternalNotification={newInternalNotification}
        setNewInternalNotification={setNewInternalNotification}
        createInternalNotification={createInternalNotification}
        allUsers={allUsers}
      />
      <EditInternalNotificationModal
        editInternalNotification={editInternalNotification}
        setEditInternalNotification={setEditInternalNotification}
        updateInternalNotification={updateInternalNotification}
        allUsers={allUsers}
      />
      <InternalNotificationModal
        viewInternalNotificationModal={viewInternalNotificationModal}
        setViewInternalNotificationModal={setViewInternalNotificationModal}
      />
    </div>
  );
};

export default NotificationsRoute;
