import { BaseEventHandler } from '@bm-js/h2o-shared';
import { X } from 'lucide-react';
import styled from 'styled-components';

const StyledMultiSelectInput = styled.div`
  .selectedOption {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  .label {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
`;

type Props = {
  value: string[];
  onChange: BaseEventHandler<string[]>;
  options: string[];
  name: string;
  emptyOptionLabel: string;
  label: string;
};

const MultiSelectInput = ({
  value,
  onChange,
  options,
  name,
  emptyOptionLabel,
  label,
}: Props) => {
  const _onChange = (value: string[]) => {
    onChange({ target: { value, name } });
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value: _value } = e.target;
    _onChange([...value, _value]);
  };

  const removeOption = (option: string) => {
    _onChange(value.filter((o) => o !== option));
  };

  return (
    <StyledMultiSelectInput>
      <p className="label">{label}</p>
      <select value={''} onChange={onSelectChange}>
        <option value={''}>{emptyOptionLabel}</option>
        {options.map((option) => (
          <option key={option} value={option} disabled={value.includes(option)}>
            {option}
          </option>
        ))}
      </select>
      <div className="selectedOptions">
        {value.map((option) => (
          <div key={`selected_option_${option}`} className="selectedOption">
            {option}
            <X width={20} onClick={() => removeOption(option)} />
          </div>
        ))}
      </div>
    </StyledMultiSelectInput>
  );
};

export default MultiSelectInput;
